import "./styles.css";
const Section4 = () => {
    return(
        <section class="ftco-section bg-light">
    	<div class="container-fluid">
    		<div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-5 heading-section ftco-animate">
            <h2 class="mb-4" style={{fontSize:"48px"}}>Our Causes</h2>
            <p style={{fontSize:"18px",color:"black"}}><span style={{fontWeight:"600",color:"black"}}>Feed:</span> Provide nourishment and support for disadvantaged school going children.</p>
			<p style={{fontSize:"18px",color:"black"}}><span style={{fontWeight:"600",color:"black"}}>Educate:</span> Facilitate students with quality education to enable them reach their full potential.</p>
            <p style={{fontSize:"18px",color:"black"}}><span style={{fontWeight:"600",color:"black"}}>Empower:</span>	Empower the local communites to be self sustaining.</p>
			<p style={{fontSize:"18px",color:"black"}}><span style={{fontWeight:"600",color:"black"}}>Develop:</span>	Develop the local community by providing professional, logistical and financial support.</p>
          </div>
        </div>
    		<div class="row">
    			<div class="col-md-12 ftco-animate">
    				<div class="carousel-cause owl-carousel">
	    				<div class="item">
	    					<div class="cause-entry">
		    					{/* <a href="#" class="img" id="img"></a> */}
		    					<div class="text p-3 p-md-4">
		    						<h3><a href="/">Clean water for the urban area</a></h3>
		    						<p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</p>
		    						<span class="donation-time mb-3 d-block">Last donation 1w ago</span>
		                <div class="progress custom-progress-success">
		                  <div class="progress-bar bg-primary" role="progressbar" style={{width: '28%'}} aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div>
		                </div>
		                <span class="fund-raised d-block">$12,000 raised of $30,000</span>
		    					</div>
		    				</div>
	    				</div>
	    				<div class="item">
	    					<div class="cause-entry">
		    					{/* <a href="#" class="img" id="img2"></a> */}
		    					<div class="text p-3 p-md-4">
		    						<h3><a href="/">Clean water for the urban area</a></h3>
		    						<p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</p>
		    						<span class="donation-time mb-3 d-block">Last donation 1w ago</span>
		                <div class="progress custom-progress-success">
		                  <div class="progress-bar bg-primary" role="progressbar" style={{width: "28%"}} aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div>
		                </div>
		                <span class="fund-raised d-block">$12,000 raised of $30,000</span>
		    					</div>
		    				</div>
	    				</div>
	    				<div class="item">
	    					<div class="cause-entry">
		    					{/* <a href="#" class="img" id="img3"></a> */}
		    					<div class="text p-3 p-md-4">
		    						<h3><a href="/">Clean water for the urban area</a></h3>
		    						<p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</p>
		    						<span class="donation-time mb-3 d-block">Last donation 1w ago</span>
		                <div class="progress custom-progress-success">
		                  <div class="progress-bar bg-primary" role="progressbar" style={{width: "28%"}} aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div>
		                </div>
		                <span class="fund-raised d-block">$12,000 raised of $30,000</span>
		    					</div>
		    				</div>
	    				</div>
	    				<div class="item">
	    					<div class="cause-entry">
		    					{/* <a href="#" class="img" id="img4"></a> */}
		    					<div class="text p-3 p-md-4">
		    						<h3><a href="/">Clean water for the urban area</a></h3>
		    						<p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</p>
		    						<span class="donation-time mb-3 d-block">Last donation 1w ago</span>
		                <div class="progress custom-progress-success">
		                  <div class="progress-bar bg-primary" role="progressbar" style={{width: "28%"}} aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div>
		                </div>
		                <span class="fund-raised d-block">$12,000 raised of $30,000</span>
		    					</div>
		    				</div>
	    				</div>
	    				<div class="item">
	    					<div class="cause-entry">
		    					{/* <a href="/" class="img" id="img5"></a> */}
		    					<div class="text p-3 p-md-4">
		    						<h3><a href="/">Clean water for the urban area</a></h3>
		    						<p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</p>
		    						<span class="donation-time mb-3 d-block">Last donation 1w ago</span>
		                <div class="progress custom-progress-success">
		                  <div class="progress-bar bg-primary" role="progressbar" style={{width: "28%"}} aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div>
		                </div>
		                <span class="fund-raised d-block">$12,000 raised of $30,000</span>
		    					</div>
		    				</div>
	    				</div>
	    				<div class="item">
	    					<div class="cause-entry">
		    					{/* <a href="/" class="img"  id="img6"></a> */}
		    					<div class="text p-3 p-md-4">
		    						<h3><a href="/">Clean water for the urban area</a></h3>
		    						<p>Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</p>
		    						<span class="donation-time mb-3 d-block">Last donation 1w ago</span>
		                <div class="progress custom-progress-success">
                        <div class="progress-bar bg-primary" role="progressbar" style={{width: "28%"}} aria-valuenow="28" aria-valuemin="0" aria-valuemax="100"></div>
		                </div>
		                <span class="fund-raised d-block">$12,000 raised of $30,000</span>
		    					</div>
		    				</div>
	    				</div>
    				</div>
    			</div>
    		</div>
    	</div>
    </section>
    )
}
export default Section4